<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="sign_page">
            <div class="form_wrap">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li">
                    <div class="form_ttl">아이디</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="text"
                            placeholder="아이디"
                            v-model="userId"
                          />
                        </label>
                      </div>
                      <div class="hint" :class="{ on: valid.userId.$error }">
                        <p>{{ msg.userId }}</p>
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">비밀번호</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="password"
                            placeholder="비밀번호는 4자리 이상 입력해 주세요."
                            v-model="userPw"
                          />
                        </label>
                      </div>
                      <div class="hint" :class="{ on: valid.userPw.$error }">
                        {{ msg.userPw }}
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">비밀번호 확인</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="password"
                            placeholder="비밀번호"
                            v-model="userConfirmPw"
                          />
                        </label>
                      </div>
                      <div
                        class="hint"
                        :class="{ on: valid.userConfirmPw.$error }"
                      >
                        {{ msg.userConfirmPw }}
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">핸드폰 번호</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input
                            type="number"
                            placeholder="번호를 입력해 주세요."
                            v-model="userPhone"
                            :disabled="auth.isSendAuth"
                            oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                            inputmode="decimal"
                            pattern="[0-9]*"
                          />
                        </label>
                        <button
                          class="btn bo"
                          @click="fnSendAuth"
                          :disabled="isDone"
                        >
                          {{ auth.label }}
                        </button>
                      </div>
                      <div class="hint on">{{ msg.userPhone }}</div>
                      <div class="inline" v-show="auth.isSendAuth">
                        <label class="input timer">
                          <input
                            type="number"
                            placeholder="코드"
                            v-model="auth.authCode"
                            :disabled="auth.isConfirm"
                            :readonly="isDone"
                            oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                            inputmode="decimal"
                            pattern="[0-9]*"
                          />
                          <span
                            class="time"
                            v-show="auth.isRunning"
                            style="width: 20%"
                            >{{ auth.count }}</span
                          >
                        </label>
                        <button
                          class="btn bo"
                          @click="fnConfirm"
                          :disabled="isAuthDone"
                        >
                          확인
                        </button>
                      </div>
                      <div class="hint on">{{ msg.authCode }}</div>
                    </div>
                  </li>
                  <li class="form_li_center">
                    <div class="form_in">
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          :true-value="1"
                          :false-value="0"
                          @click="fnMarketing"
                          v-model="isMarketing"
                        />
                        <span class="label"
                          ><span
                            >(선택) 마케팅(광고) 정보 앱 푸쉬 동의</span
                          ></span
                        >
                      </label>
                      <p class="hint txt on">
                        회원가입 시
                        <span class="medium" @click="fnTerms(1)"
                          >개인정보 처리 방침</span
                        >및
                        <span class="medium" @click="fnTerms(2)"
                          >서비스 이용약관</span
                        >에 동의합니다.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="page_btn">
          <div class="btn_wrap">
            <button
              class="btn"
              @click="fnSignUp"
              :class="{ on: isFillUp }"
              :disabled="!isFillUp"
            >
              회원가입
            </button>
            <!--입력되면 on-->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { joinState } from './index.js'

export default {
  name: 'sign-up-index',
  description: '회원가입 페이지',
  setup(props) {
    const state = joinState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
