import { getCurrentInstance, reactive, watch, toRefs, onUnmounted } from 'vue'
import { useVuelidate } from '@vuelidate/core'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
// import CONFIRM from '@/components/common/modal/alert/confirm'
import PUSH from '@/components/common/modal/alert/push'

export const joinState = () => {
  const { proxy } = getCurrentInstance()
  const store = useStore()
  const router = useRouter()

  const state = reactive({
    userId: '',
    userPw: '',
    userConfirmPw: '',
    userPhone: '',
    isMarketing: 0,
    auth: {
      label: '인증',
      authCode: '',
      authConfirmCode: '',
      isSendAuth: false, // 인증번호 발송시 true
      isConfirm: false, // 인증번호 일치할시 true
      isRunning: false, // 타이머 진행 여부
      count: '05:00',
      timeCount: 300,
      timer: ''
    },
    msg: {
      userId: '',
      userPw: '',
      userPhone: '',
      userConfirmPw: '',
      authCode: ''
    },
    isDone: false,
    isAuthDone: false,
    isFillUp: false // 회원가입폼이 모두 입력되었을때 true
  })

  /** validations **/
  const rules = {
    userId: {
      test: function(val) {
        if (proxy.$Util.isEmpty(val)) {
          state.msg.userId = '아이디를 입력해 주세요.'
          return false
        }
        if (val.length < 6) {
          state.msg.userId = '아이디는 6자 이상 입력해야 합니다.'
          return false
        }
        return true
      }
    },
    userPw: {
      test: function(val) {
        if (proxy.$Util.isEmpty(val)) {
          state.msg.userPw = '비밀번호를 입력해 주세요.'
          return false
        }
        if (val.length < 4 || val.length > 11) {
          state.msg.userPw =
            '비밀번호는 4자리 이상 12자리 이하 입력해야 합니다.'
          return false
        }
        return true
      }
    },
    userConfirmPw: {
      test: function(val) {
        if (proxy.$Util.isEmpty(val)) {
          state.msg.userConfirmPw = '비밀번호를 입력해 주세요.'
          return false
        }
        if (val.length < 4 || val.length > 11) {
          state.msg.userConfirmPw =
            '비밀번호는 4자리 이상 12자리 이하 입력해야 합니다.'
          return false
        }
        if (state.userPw !== state.userConfirmPw) {
          state.msg.userConfirmPw = '비밀번호가 일치하지 않습니다.'
          return false
        }
        return true
      }
    }
  }

  /** watch **/
  watch(state, async () => {
    if (
      !proxy.$Util.isEmpty(state.userId) &&
      !proxy.$Util.isEmpty(state.userPw) &&
      !proxy.$Util.isEmpty(state.userConfirmPw) &&
      state.isDone === true
    ) {
      state.isFillUp = true
    }
  })

  onUnmounted(() => {
    fnStop()
  })

  // 인증번호 최초 클릭시
  const fnSendAuth = async () => {
    if (proxy.$Util.isEmpty(state.userPhone)) {
      state.msg.userPhone = '휴대폰 번호를 입력해주세요.'
      return
    }
    if (!proxy.$Util.isPhoneValid(state.userPhone)) {
      state.msg.userPhone = '휴대폰 형식이 올바르지 않습니다.'
      return
    } else {
      state.msg.userPhone = ''
    }
    if (state.auth.label === '재시도') {
      fnStop()
      state.auth.label = '인증'
      state.auth.authCode = ''
      state.msg.authCode = ''
      state.auth.isSendAuth = false
      state.auth.isRunning = false
      state.auth.isConfirm = false
      return false
    }
    const res = await proxy.$SignSvc.send({
      phone: state.userPhone,
      type: 'join'
    })
    if (res.code === 1) {
      alert('인증번호가 발송되었습니다.')
      state.auth.authConfirmCode = res.entity
      state.auth.isSendAuth = true
      state.auth.label = '재시도'
      state.auth.isRunning = true
      state.auth.isConfirm = false
      // 발신번호 등록 완료시 res.entity -> ''
      state.auth.authCode = ''
      fnTimer()
    } else if (res.code === -2005) {
      alert('회원으로 등록된 휴대폰 번호 입니다.')
    }
  }

  // 인증번호가 일치여부 확인
  const fnConfirm = async () => {
    if (proxy.$Util.isEmpty(state.auth.authCode)) {
      state.msg.authCode = '인증번호를 입력해주세요.'
      return
    } else {
      state.msg.authCode = ''
    }
    // const params = {
    //   phone: state.userPhone,
    //   authCode: state.auth.authCode
    // }
    // const res = await proxy.$SignSvc.match(params)
    if (state.auth.authCode === state.auth.authConfirmCode) {
      alert('인증되었습니다.')
      state.auth.isRunning = false
      state.isAuthDone = true
      state.isDone = true
      fnStop()
    } else {
      alert('인증번호가 일치하지 않습니다.')
    }
  }
  const fnTimer = () => {
    fnStop()
    state.auth.timer = setInterval(() => {
      state.auth.timeCount--
      if (state.auth.timeCount <= 0) {
        fnStop()
        alert('인증번호가 만료되었습니다. \n다시 인증해주세요')
        state.auth.isConfirm = true
        state.auth.isRunning = false
        state.isAuthDone = true
      }
      let time = state.auth.timeCount / 60
      let min = parseInt(time)
      let sec = Math.round((time - min) * 60)
      state.auth.count = pad(min, 2) + ':' + pad(sec, 2)
    }, 1000)
  }

  const fnStop = () => {
    clearInterval(state.auth.timer)
    state.auth.timeCount = 300
    state.auth.count = '05:00'
  }

  const pad = (num, width) => {
    num = num + ''
    return num.length >= width
      ? num
      : new Array(width - num.length + 1).join('0') + num
  }

  const valid = useVuelidate(rules, state)

  // 마케팅 동의시 뜨는 알럿 모달
  const fnMarketing = async () => {
    const payload = {}
    payload.component = PUSH
    payload.layout = {
      contents: `${state.isMarketing === 0 ? '동의' : '거부'}`
    }
    payload.data = {}
    payload.callBack = async modalAlertProxy => {
      modalAlertProxy.$emit('close')
    }
    await store.dispatch('layout/pushModalAlertComponent', payload)
  }

  // 서비스 이용약관 동의시시 뜨는 전체 모달
  const fnTerms = async type => {
    const payload = {}
    if (type === 1) {
      payload.component = proxy.$modalNames.TERMS
    } else {
      payload.component = proxy.$modalNames.PRIVACY
    }
    payload.layout = {}
    payload.data = {}
    payload.callBack = ''
    await store.dispatch('layout/pushModalComponent', payload)
  }

  const fnSignUp = async () => {
    valid.value.$touch()
    if (valid.value.$invalid) return
    if (!state.isFillUp) return
    if (!confirm('회원가입 하시겠습니까?')) return false
    const res = await proxy.$SignSvc.join(state)
    if (res.code === 1) {
      alert('가입되었습니다.')
      await router.push({ path: '/sign/in' })
    } else if (res.code === -1001) {
      alert('중복된 아이디가 존재합니다.')
    }
    // const payload = {}
    // payload.component = CONFIRM
    // payload.layout = {
    //   title: '알림',
    //   contents: '회원가입하시겠습니까?'
    // }
    // payload.data = {}
    // payload.callBackDone = async () => {
    //   const res = await proxy.$SignSvc.join(state)
    //   if (res.code === 1) {
    //     alert('가입되었습니다.')
    //     await router.push({ path: '/sign/in' })
    //   } else if (res.code === -1001) {
    //     alert('중복된 아이디가 존재합니다.')
    //     // await proxy.$emit('close')
    //   }
    // }
    // payload.callBackCancel = proxy => {
    //   proxy.$emit('close')
    // }
    // await store.dispatch('layout/pushModalAlertComponent', payload)
  }

  return {
    ...toRefs(state),
    fnSendAuth,
    fnConfirm,
    valid,
    fnMarketing,
    fnTerms,
    fnSignUp
  }
}
